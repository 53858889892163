.not-found-page {
    background: rgba($color: $primary, $alpha: 0.1);
    border: 1px solid $primary;
    border-radius: 8px;
    min-height: 50vh;
  
    .title {
      font-weight: $font-weight-bolder;
      font-size: $h1-font-size;
      color: #363636;
    }
    .info {
      font-weight: $font-weight-normal;
      font-size: $title-font-size;
      span{
        color: $primary;

      }
    }
  }
  