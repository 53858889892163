.header-section {
  height: 96px;
  padding: 8px 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);

  .profile-icon {
    height: 2.5em;
    width: 2.5em;
  }
  .userdetail {
    @include max-break-point(tablet) {
      display: none;
    }
    .username {
      font-weight: $font-weight-mid-bold;
      font-size: $title-font-size;
    }
    .surname {
      font-weight: $font-weight-normal;
      font-size: $p-font-size;
      color: #6b6b6b;
    }
  }
  .dropdown-icon {
    height: 1.2em;
    width: 1.2em;
  }
  .brand-logo {
    height: 5em;
    width: 5em;
  }
}
