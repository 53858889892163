.model-detail-container {
  margin: 1rem 7rem;
  @media only screen and (max-width: 1100px) {
    margin: 0.5rem 3rem;
  }
  @media only screen and (max-width: 900px) {
    margin: 0.5rem 1rem;
  }
  @include max-break-point(tablet) {
    padding: 2rem;
  }
  @media only screen and (max-width: 576px) {
    margin: 0.5rem 0rem;
  }

  .model-detail-alert {
    padding: 8px;
    background-color: rgba($color: $primary, $alpha: 0.1);
    border: 2px solid $primary;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .close {
      font-size: 1.4rem;
      color: $primary;
    }
    @include max-break-point(tablet) {
      display: none;
    }
  }

  .model-detail-mobile-alert {
    padding: 8px;
    background-color: rgba($color: $primary, $alpha: 0.1);
    border: 2px solid $primary;
    border-radius: 5px;
    font-size: 0.8rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    display: none;
    margin: 0rem -5px 0rem -5px;

    .close {
      font-size: 1.4rem;
      color: $primary;
    }
    @include max-break-point(tablet) {
      display: flex;
    }
  }

  .video-grid {
    margin-top: 1rem;
    @include max-break-point(tablet) {
      margin-top: 0rem;
    }

    .video-info {
      margin: 1rem 0rem 1rem 0rem;
      @include max-break-point(tablet) {
        margin: 1rem 1rem 1rem 1rem;
      }

      .video-info-title {
        font-size: 1.6rem;
        font-weight: $font-weight-bold;
        color: $primary;
        margin-top: 2rem;
        margin-bottom: 2rem;
      }

      div {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        font-size: 0.9rem;
        @include max-break-point(tablet) {
          grid-template-columns: repeat(2, 1fr);
          gap: 0.5rem;
          border: 2px solid rgba($color: $primary, $alpha: 0.2);
          border-radius: 5px;
          background-color: rgba($color: $primary, $alpha: 0.05);
        }
        span {
          @include max-break-point(tablet) {
            padding: 1rem;
          }
          strong {
            font-weight: 600;
          }
        }
      }
    }

    .instruction-section {
      background: $light-1;
    }

    .instructions {
      margin-top: 2rem;
      margin-bottom: 2rem;
      max-height: 30vh;
      overflow-y: auto;

      @include max-break-point(tablet) {
        margin-right: 1rem;
        margin-left: 1rem;
      }
      div {
        font-size: 0.9rem;

        img {
          width: 100%;
        }
      }
    }
    
    .accordion-item {
      margin-top: 1rem;
      background-color: $light-2 !important;
      border-radius: 4px;
      .accordion-header {
        background-color: $light-2 !important;
        border-radius: 4px;
        .accordion-button {
          background-color: $light-2 !important;
          border-radius: 4px;
        }
      }
      .accordion-body {
        font-size: 13px;
        background-color: $light-2 !important;
        margin-bottom: 1rem;
        border-radius: 4px;
        border-bottom: 1px solid rgba($color: $primary, $alpha: 0.2);
        span {
          margin-left: 1rem;
          padding-top: 1rem;
        }
        input {
          display: none;
        }
        label {
          font-size: 1rem;
        }
      }
    }

    .comment {
      margin-top: 1rem;
      border: 2px $primary solid;
      background-color: rgba($color: $primary, $alpha: 0.2) !important;
      border-radius: 5px;
      .comment-input {
        border: none;
        background-color: rgba($color: $primary, $alpha: 0.1) !important;
      }
      .comment-button {
        background-color: $white;
        color: $black;
        margin: 0px 1.3rem 0px 1.3rem;
        background-color: rgba($color: $primary, $alpha: 0.1) !important;
      }
      @media (max-width: 1200px) {
        margin-bottom: 1rem;
      }
    }

    .row {
      margin: 0px;
      padding: 0%;
      @include max-break-point(tablet) {
        margin: 0rem -1.8rem 0rem -1.8rem;
      }
      @media (max-width: 576px) {
        margin: 0rem -0.7rem 0rem -0.7em;
      }
      .col-8 {
        padding: 0px;
      }
      .col-4 {
        padding-right: 0px;
      }
      .current-video {
        border-radius: 10px;
        overflow: hidden;
        border: 2px solid rgba($color: $primary, $alpha: 0.5);
        aspect-ratio: 16/9;

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }

        .icon {
          color: $primary;
          font-size: 2rem;
          cursor: pointer;
          stroke: $white;
        }
        video {
          border-radius: 10px;
          aspect-ratio: 16/9;
        }
        .start-learning {
          width: 100%;
          bottom: 0em;
          background: linear-gradient(
            360deg,
            $white 0%,
            rgba($color: $white, $alpha: 0) 231.37%
          );
          .commence {
            font-weight: $font-weight-bold;
            font-size: $title-font-size;
          }
        }
      }

      .next-videos {
        border-radius: 5px;
        border: 2px solid rgba($color: $primary, $alpha: 0.3);
        background-color: rgba($color: $primary, $alpha: 0.05);
        min-height: 20rem;

        h5 {
          font-size: 1rem;
          padding-left: $title-font-size;
          margin-bottom: 0px;
        }

        .next-videos-item {
          border-radius: 12px;
          margin: 1rem 0rem 1rem 0rem;
          cursor: pointer;
          position: relative;
          aspect-ratio: 16/9;
          overflow: hidden;
          border: 5px solid transparent;

          &.active {
            border-color: $primary;
          }

          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
            border-radius: 8px;
          }

          .next-videos-title {
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 1rem;
            font-weight: $font-weight-bold;
            font-size: $title-font-size;
            z-index: 10;
            width: 100%;
            background: linear-gradient(
              360deg,
              $white 0%,
              rgba($color: $white, $alpha: 0) 231.37%
            );
            border-radius: 0px 0px 8px 8px;
          }
        }
        .locked-section {
          top: 0;
          z-index: 999;
          width: 100%;
          height: 100%;
          background: rgba($color: $black, $alpha: 0.6);
          backdrop-filter: blur(2px);
          .locked-information {
            font-weight: $font-weight-bold;
            font-size: $title-font-size;
          }
        }
        @media (max-width: 1200px) {
          margin-top: 2rem;
        }
      }
    }
  }
  .alert-model {
    background-color: rgba($color: $primary, $alpha: 0.3);
    border-radius: 0px;
    border: none;
    color: $black;
  }
}
