//media-query
@mixin max-break-point($point) {
  $mobile-phone: 576px;

  $tablet: 768px;

  $tablet-landscape: 991px;

  $large-desktop: 1200px;

  $larger-desktop: 1400px;

  @if $point == mobile-phone {
    @media (max-width: #{$mobile-phone}) {
      @content;
    }
  }

  @if $point == tablet {
    @media (max-width: #{$tablet}) {
      @content;
    }
  }

  @if $point == tablet-landscape {
    @media (max-width:  #{$tablet-landscape}) {
      @content;
    }
  }

  @if $point == large-desktop {
    @media (max-width:#{$large-desktop}) {
      @content;
    }
  }

  @if $point == larger-desktop {
    @media (max-width:#{$larger-desktop}) {
      @content;
    }
  }
}

@mixin min-break-point($point) {
  $mobile-phone: 576px;

  $tablet: 768px;

  $tablet-landscape: 991px;

  $large-desktop: 1200px;

  $larger-desktop: 1400px;

  @if $point == mobile-phone {
    @media (min-width: #{$mobile-phone}) {
      @content;
    }
  }

  @if $point == tablet {
    @media (min-width: #{$tablet}) {
      @content;
    }
  }

  @if $point == tablet-landscape {
    @media (min-width:  #{$tablet-landscape}) {
      @content;
    }
  }

  @if $point == large-desktop {
    @media (min-width:#{$large-desktop}) {
      @content;
    }
  }

  @if $point == larger-desktop {
    @media (min-width:#{$larger-desktop}) {
      @content;
    }
  }
}

//transform
@mixin transform($action) {
  transform: $action;
}

@mixin translate($x-axis, $y-axis) {
  transform: translate($x-axis, $y-axis);
}

@mixin translate-y($y-axis) {
  transform: translateY($y-axis);
}

@mixin translate-x($x-axis) {
  transform: translateX($x-axis);
}

// user select

@mixin user-select($value) {
  user-select: $value;
}

@mixin size($height, $width: $height) {
  height: $height;
  width: $width;
}

@mixin scale($value) {
  transform: scale($value);
}

@mixin scale-x($x-value) {
  transform: scaleX($x-value);
}

@mixin scale-y($y-value) {
  transform: scaleY($y-value);
}

// image url
@mixin image-property {
  appearance: none;
  background: none;

  transition: 50ms ease-in-out;

  transition-property: background-color, color;
}

//box-shadow
@mixin box-shadow(
  $horizontal,
  $vertical,
  $color,
  $blur: 0,
  $spread: 0,
  $inset: false
) {
  @if $inset {
    box-shadow: $horizontal $vertical $blur $spread $color inset;
  } @else {
    box-shadow: $horizontal $vertical $blur $spread $color;
  }
}

@mixin rotate($value) {
  transform: rotate($value);
}

@mixin keyframe($animation_name) {
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation(
  $duration,
  $animation,
  $timing,
  $iteration,
  $delay:none,
  $direction: normal,
  $fillmode: none,
  $running: running
) {
  animation-delay: $delay;
  animation-direction: $direction;
  animation-duration: $duration;
  animation-fill-mode: $fillmode;
  animation-iteration-count: $iteration;
  animation-name: $animation;
  animation-play-state: $running;
  animation-timing-function: $timing;
}
