// award section
.dropdown-icon {
  background-color: $white !important;
}
.dropdown-icon:active {
  background-color: $white !important;
}

.achievement-wrap {
  display: flex;
  overflow-x: auto;

  .achievement-card {
    display: flex;
    align-items: center;
    background-color: $white;
    color: $primary;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 8px;
    border-radius: 8px;
    padding: 1rem;
    margin: 10px;
    min-width: 168px;

    &.view-all {
      text-align: center;
      background: $primary;
      color: $white;
    }

    img {
      width: 100%;
    }
  }
}
