.footer-wrap {
  .footer-action {
    ul {
      display: flex;
      justify-content: space-between;
      list-style: none;
      padding: 2rem 1rem;
      margin: 0px;
      font-size: 14px;
      @media (max-width: 950px) {
        display: grid;
        text-align: center;
        justify-content: center;
      }
      li {
        @media (max-width: 950px) {
          margin-bottom: 0.5rem;
        }
        a {
          color: $black;
        }
      }
    }
  }
}
