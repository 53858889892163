.preloader {
  .animation-preloader {
    z-index: 1000;
    .spinner {
      -webkit-animation: spinner 1s infinite linear;
      animation: spinner 1s infinite linear;
      border-radius: 50%;
      border: 3px solid rgba(0, 0, 0, 0.2);
      border-top-color: #000000;
      height: 4em;
      margin: 0 auto 3.5em auto;
      width: 4em;
    }
  }
}
@keyframes spinner {
  to {
    -webkit-transform: rotateZ(360deg);
    transform: rotateZ(360deg);
  }
}
