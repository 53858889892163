.user-details {
  .user-name {
    font-weight: $font-weight-mid-bold;
    font-size: $h1-font-size;
    text-transform: capitalize;
  }
  .detail-card {
    border-radius: $border-radius;
  }
  .title {
    color: $primary;
    font-weight: $font-weight-mid-bold;
    font-size: $title-font-size;
  }
  .number {
    font-weight: $font-weight-normal;
    font-size: $title-font-size;
    color: $dark-6;
  }
  .progressbar-container {
    width: 75%;
    background: $light-8;
    border-radius: 4px;
  }
  .progress-value {
    height: 0.9em;
    float: left;
    border-radius: 4px;
    background: $primary;
  }

  .learning-completed {
    background: rgba(29, 178, 126, 0.2);
    border: 1px solid $primary;
    border-radius: $border-radius;
    font-weight: $font-weight-mid-bold;
    font-size: $title-font-size;
  }
  .learning-pending {
    background: rgba(72, 165, 233, 0.2);
    border: 1px solid #48a5e9;
    border-radius: $border-radius;
    font-weight: $font-weight-mid-bold;
    font-size: $title-font-size;
  }
}
