.model-details {
  .title-image {
    height: 12em;
  }
  .model-detail-section {
    background: rgba(29, 178, 126, 0.05);
    border: 2px solid #1db27e;
    box-shadow: 3px 3px 10px rgba($color: $primary, $alpha: 0.1);
    border-radius: 2px;
    .edit-section {
      border: 2px solid $primary;
      border-radius: 12px;
      background-color: $white;
      top: -1.25em;
      right: 3em;
      z-index: 99;
    }
  }
  .model-attribute {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    .value {
      color: $dark-6;
      word-break: break-all;
    }
    .link {
      color: $blue;
    }
  }
  .operation-list {
    margin-left: 1.5rem !important;

    .operation-detail {
      font-weight: 600;
      font-size: 14px;
      .operation-id {
        color: $dark-6;
      }
    }
    .operation {
      @include size(10em);
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
      }
    }
    .title {
      p {
        font-weight: $font-weight-mid-bold;
        font-size: $h5-font-size;
      }
    }
    .add-operation {
      border: 2px dashed $primary;
      h4 {
        color: $primary;
      }
    }
    @media (max-width: 767px) {
      margin-left: 0px !important;
    }
  }
}
