* {
  border: 0;
  box-sizing: border-box;
  font-family: $default-font-family;
  margin: 0;
  padding: 0;
}

html,
body {
  min-height: 100vh;
  min-width: 20em;
}

#root {
  background: #fefeff;
}
