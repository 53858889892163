.terms-wrap {
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 4rem;
    margin: 4rem;
    border-radius: 12px;
    margin-top: 0px;
}

.terms-title {
    color: #1db27e;
    font-size: 28px;
    text-align: center;
    display: flex;
    justify-content: center;
    margin: 2rem;
}
