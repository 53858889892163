.search-section-wrap {
  background-color: rgba($color: $primary, $alpha: 0.1);
  padding: 2rem 0px;
  margin: 2rem 0px;

  @include max-break-point(tablet) {
    padding: 0px;
    background-color: transparent;
  }

  .univ-input {
    outline: none;
    padding: 0.5rem;
    border: 1px solid #c2c9d1;
    border-radius: 4px;
    width: 100%;
    background: white;
    margin-top: 0.5rem;
  }

  .search-input {
    outline: none;
    padding: 0.5rem;
    width: 100%;
    @include max-break-point(tablet) {
      background-color: rgba($color: $primary, $alpha: 0.003);
      border: none;
      display: none;
    }
  }

  .search-btn {
    background-color: $primary;
    border-radius: 8px;
    color: $white;
    padding: 0.5rem 2rem;
    min-width: 100px;
    margin-left: 10px;
    @include max-break-point(tablet) {
      display: none;
    }
  }
  .search-icon {
    background-color: $primary;
    border-radius: 8px;
    color: $white;
    padding: 0.5rem 1rem;
    margin-right: 10px;
    margin-left: 10px;
    display: none;
    img {
      width: 25px;
      display: none;
      @include max-break-point(tablet) {
        display: block;
        margin: 0px;
      }
    }
    @include max-break-point(tablet) {
      display: block;
    }
  }

  .advance-search-btn {
    background-color: $primary;
    border-radius: 8px;
    color: $white;
    padding: 0.5rem 2rem;
    min-width: 100px;
    margin-left: 10px;
    margin-top: 3rem;
    @include max-break-point(tablet) {
      margin-top: 2rem;
    }
  }

  .filter-btn {
    background-color: $primary;
    border-radius: 8px;
    color: $white;
    padding: 0.5rem 0.8rem;
    margin-left: 10px;
    margin-right: 1rem;
    @include max-break-point(tablet) {
      margin: 0px;
      display: none;
    }
  }
  .search-icon-wrap {
    @include max-break-point(tablet) {
      justify-content: end;
    }
  }
  .input-wrap {
    @include max-break-point(tablet) {
      background: rgba($color: $primary, $alpha: 0.2);
      padding: 10px;
      border-radius: 3px;
      border: 1px solid $primary;
    }
  }
}
.search-container {
  background-color: $light-1;
  height: 100vh;
  padding: 1rem;
  .search-input {
    border-radius: 4px;
    width: 100%;
  }
  .univ-input {
    width: 100%;
    margin-bottom: 1rem;
    margin-top: 0.5rem;
    padding: 0.5rem 1rem 0.5rem 0.5rem;
    border-radius: 4px;
    outline: none;
  }
  .advance-search-btn {
    background-color: $primary;
    border-radius: 8px;
    color: $white;
    padding: 0.5rem 2rem;
    min-width: 100px;
    margin-left: 10px;
    margin-top: 1rem;
  }
  .search-icon {
    background: $primary;
    border-radius: 8px;
    color: $white;
    padding: 0.5rem 1rem;
    margin-right: 10px;
    margin-left: 10px;
    position: relative;
    // top: 6px;
    img {
      width: 25px;
      margin-top: 10px;
      @include max-break-point(tablet) {
        display: block;
        margin: 0px;
      }
    }
  }
  .advance-search, .advance-search:focus{
    padding: 0;
    background-color: #fcfcf4;
    color: #000;
    border: 1px solid #fcfcf4;
    box-shadow: none;
  }
  .advance-search-body{
    background-color: #fcfcf4;
    color: #000;
  }
}
