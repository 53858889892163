.overlay {
  background: rgba($color: $black, $alpha: 0.4);
  backdrop-filter: blur(7.5px);
  border-radius: 0;
  inset: 0;
  z-index: 9999;
}
input[type="file"]::-webkit-file-upload-button {
  display: none;
}
.custom-modal {
  border-radius: 0.625em;
  width: 50%;
  @media only screen and (max-width: 800px) {
    width: 90%;
  }
  .title {
    font-weight: 700;
    font-size: 16px;
  }
  .modal-header,
  .modal-footer {
    background-color: $light-6;
  }
  .quiz-header {
    font-size: 20px;
    font-weight: 600;
  }
  .operation-modal {
    .edit-quiz {
      top: -1.15em;
      right: 2em;
      background-color: $primary;
      border-radius: 4px;
      color: $white;
    }
    .delete-icon {
      border-left: 1px solid $white;
    }
    .quiz-title {
      font-weight: $font-weight-mid-bold;
      font-size: $p-font-size;
    }
    .correct-option {
      color: $primary;
    }
  }
  .quiz-modal {
    .quiz-question {
      font-weight: $font-weight-normal;
      font-size: $h5-font-size;
    }
    .quiz-options {
      background: rgba($color: $primary, $alpha: 0.1);
      border: 1px solid $primary;
      border-radius: 8px;
      width: 100%;
    }
    .form-label {
      margin-bottom: 0px !important;
    }
    .form-check-input {
      background-color: transparent;
      border: 2px solid rgba($color: $primary, $alpha: 0.8);
    }
    .form-check-input:checked {
      background-color: $primary !important;
    }
    .total-quiz {
      p {
        font-weight: $font-weight-normal;
        font-size: $title-font-size;
      }
      .count {
        font-weight: $font-weight-mid-bold;
      }
      span {
        color: $primary;
      }
    }
  }
  .congratulations-modal {
    background-image: url("./../../images/Celebrate.svg");
    .title {
      font-weight: $font-weight-bold;
      font-size: $h1-font-size;
      color: $primary;
    }
    .description {
      font-weight: $font-weight-bold;
      font-size: $h5-font-size;
      color: $dark-9;
    }
  }
  .failed-modal {
    border: 1px solid $primary;
    border-radius: 8px;
    .title {
      font-weight: $font-weight-bold;
      font-size: $h1-font-size;
    }
    .info {
      font-weight: $font-weight-bold;
      font-size: $h5-font-size;
    }
  }
}
