.landing-page-container {
  .hero-desc {
    font-size: 40px;
    font-weight: 800;
    color: $dark-9;

    @include max-break-point(tablet) {
      padding: 15px;
    }
  }
  .hero-img1 {
    width: 100%;
  }

  .hero-img2 {
    width: 100%;
    height: 13rem;

    @include max-break-point(tablet) {
      height: 8rem;
    }
  }

  .hero-text {
    color: #525050;
    font-weight: 600;
    display: block;

    @include max-break-point(tablet) {
      padding-left: 15px;
    }
  }

  .hero-title {
    display: inline-flex;
    font-size: 3rem;
    font-weight: 800;
    color: #525050;

    margin-bottom: 20px;
    border-bottom: 5px solid $primary;

    @include max-break-point(tablet) {
      margin-top: 1rem;
      margin-bottom: 3rem;
      margin-left: 15px;
      text-align: center;
    }
  }

  .query {
    text-align: center;
  }

  .feature-title {
    text-align: center;
    margin-top: 5rem;
    @media (max-width: 767px) {
      margin-top: 2rem;
      margin-bottom: 1rem;
    }
  }
  .feature-img {
    margin-top: 4rem;
    @media (max-width: 767px) {
      max-height: 400px;
      min-height: 250px;
    }
    @media (max-width: 360px) {
      max-height: 300px;
    }
  }
  .about-section {
    @media (max-width: 767px) {
      margin-top: 3rem;
    }
    .kikisapo-desc {
      line-height: 2rem;
      @media (max-width: 767px) {
        text-align: center;
      }
    }
  }
  .feature-desc {
    text-align: center;
    line-height: 2rem;
  }
  .query-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 30px 37px;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 17.71%,
        rgba(255, 255, 255, 0.8) 100%
      ),
      linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 45.99%);
    border-radius: 30px;

    h3 {
      font-family: $inter-font-family;
      font-weight: bold;
      font-size: 24px;
    }

    .form-input {
      padding: 1rem;
      margin-left: 0px;
      width: 100%;
      border: 1px solid #eceff3;
      outline: none;
    }

    .hLCrav .rnc {
      background: transparent !important;
      padding: 0;
      width: 100%;
    }

    .hLCrav .rnc-input {
      @extend .form-control, .form-input;
    }

    .submit-btn {
      background-color: #1db27e;
      padding: 1rem 5rem;
      color: #fff;
      border-radius: 8px;
      margin: 10px;
      margin-left: 0px;
      @media (max-width: 767px) {
        padding: 1rem 3rem;
      }
    }
    @media (max-width: 767px) {
      margin-top: 2rem;
    }
  }
  .contact-wrap {
    background: $white;
    border-radius: 30px;
    padding: 2rem;
    @media (max-width: 767px) {
      margin-bottom: 2rem;
    }
    h4 {
      font-weight: 600;
      padding-left: 1rem;
    }
    .contact-details {
      img {
        padding: 1rem;
      }
    }
  }
}
