.inquiry-container {
  .accordion {
    border-radius: $border-radius-lg;
    overflow: hidden;
    &:focus {
      outline: 0;
      border: 0;
      box-shadow: none;
    }
  }
  .accordion-button {
    background-color: $white;
    color: $black;
    border-bottom: 1px solid rgba(29, 178, 126, 0.4);
    
    &:focus {
      outline: 0;
      border: 0;
      box-shadow: none;
    }
  }
  .description {
    font-weight: $font-weight-bold;
    font-size: $title-font-size;
    line-height: 32px;
  }
  .title {
    font-weight: $font-weight-bold;
    font-size: $h2-font-size;
    color: $primary;
  }
  .info {
    background-color: $white;
    border-radius: $border-radius-lg;
  }
}
