p,
div {
  margin: 0;
}

img,
img a {
  border: 0;
}

textarea {
  height: 4em;
}

input {
  border-radius: 4px;
  border: 0.5px solid $dark-1;
}

.dropdown-toggle::after {
  display: none;
}
a {
  text-decoration: none;
}

.form-check-input {
  background-color: $white;
}

p {
  font-size: $p-font-size;
}

h5 {
  font-size: $h5-font-size;
}

h6 {
  font-family: $inter-font-family;
}

td {
  font-weight: $font-weight-bold;
  font-size: $p-font-size;
  color: $dark-6;
}

.link {
  color: $blue;
}

.text-primary {
  color: $primary-color !important;
}

.text-warning {
  color: $bg-warn !important;
}

.text-error {
  color: $error !important;
}
th {
  background-color: $primary !important;
  color: $white;
}

.border-primary {
  border: 1px solid $primary !important;
  border-radius: $border-radius;
}

.btn,
.btn:hover {
  border: none;
  border-radius: 4px;
  background: $soft-blue;
  font-weight: $font-weight-mid-bold;
  font-size: $title-font-size;
}

.btn-primary,
.btn-primary:hover {
  background: $primary;
  color: $white;
}

.btn-secondary,
.btn-secondary:hover {
  background: $light-8;
}

.btn-warning {
  background: $bg-warn !important;
}

.btn-danger {
  background: $error !important;
}

.browse-btn {
  background: rgba($color: $primary, $alpha: 0.2);
  border: 1px solid rgba($color: $primary, $alpha: 0.6);
}

.form-check-input {
  background-color: transparent;
  border: 2px solid rgba($color: $primary, $alpha: 0.8);
}

.form-check-input:checked {
  background-color: $primary !important;
  border: none !important;
}

.box-shadow-primary {
  box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.15);
}

.box-shadow-secondary {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px $dark-3;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $primary;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba($color: $primary, $alpha: 3);
}

.cursor {
  &-alias {
    cursor: alias;
  }

  &-all-scroll {
    cursor: all-scroll;
  }

  &-auto {
    cursor: auto;
  }

  &-cell {
    cursor: cell;
  }

  &-context-menu {
    cursor: context-menu;
  }

  &-col-resize {
    cursor: col-resize;
  }

  &-copy {
    cursor: copy;
  }

  &-crosshair {
    cursor: crosshair;
  }

  &-default {
    cursor: default;
  }

  &-e-resize {
    cursor: e-resize;
  }

  &-ew-resize {
    cursor: ew-resize;
  }

  &-grab {
    cursor: grab;
  }

  &-grabbing {
    cursor: grabbing;
  }

  &-help {
    cursor: help;
  }

  &-move {
    cursor: move;
  }

  &-n-resize {
    cursor: n-resize;
  }

  &-ne-resize {
    cursor: ne-resize;
  }

  &-nesw-resize {
    cursor: nesw-resize;
  }

  &-ns-resize {
    cursor: ns-resize;
  }

  &-nw-resize {
    cursor: nw-resize;
  }

  &-nwse-resize {
    cursor: nwse-resize;
  }

  &-no-drop {
    cursor: no-drop;
  }

  &-none {
    cursor: none;
  }

  &-not-allowed {
    cursor: not-allowed;
  }

  &-pointer {
    cursor: pointer;
  }

  &-progress {
    cursor: progress;
  }

  &-row-resize {
    cursor: row-resize;
  }

  &-s-resize {
    cursor: s-resize;
  }

  &-se-resize {
    cursor: se-resize;
  }

  &-sw-resize {
    cursor: sw-resize;
  }

  &-text {
    cursor: text;
  }

  &-w-resize {
    cursor: w-resize;
  }

  &-wait {
    cursor: wait;
  }

  &-zoom-in {
    cursor: zoom-in;
  }

  &-zoom-out {
    cursor: zoom-out;
  }
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.bg-primary {
  background: $primary;
  color: $white;
}

.table > :not(caption) > * > * {
  padding: 1rem 1.23rem !important;
}

.table > th {
  line-height: 1.9;
  font-weight: 400;
}

.form-control {
  background-color: $white !important;
  border-color: $border-color;
  border-radius: $border-radius;
}

.alert-bar {
  z-index: 1000001;

  .alert {
    @extend .d-flex, .align-items-center, .justify-content-between;
  }

  .close {
    background: transparent;
    border: 0;
    color: $black;

    &:focus {
      outline: none;
    }

    &:hover {
      background: transparent;
    }
  }
}

.alert-section {
  background: rgba($color: $primary, $alpha: 0.3);
  font-weight: $font-weight-normal;
  font-size: $p-font-size;
}

.upload-section {
  @extend .d-flex, .align-items-center, .justify-content-center;
  border: 2px dashed $primary;
  background: rgba($color: $primary, $alpha: 0.1);
  border-radius: 10px;
  height: 100% !important;
  min-height: 190px !important;
  max-height: 500px !important;
}

.text-success {
  color: $primary !important;
}

.bg-success {
  background-color: $primary !important;
}

.bg-warning {
  background-color: $bg-warn;
}

.text-warning {
  color: $bg-warn;
}

.section {
  padding: 80px 0;
  @media (max-width: 767px) {
    padding: 1rem 0px;
    margin-bottom: 3rem;
  }
}
.nav-tabs .nav-link {
  border: none !important;
  font-weight: $font-weight-normal;
  color: $dark-5;
  .nav-item {
    font-weight: $font-weight-normal;
    color: $dark-5;
  }
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border-bottom: 5px solid $primary !important;
}
