.dashboard-page {
  .page-title {
    font-weight: 400;
    font-size: 18px;
    color: #818181;
  }
  .page-heading {
    font-weight: 500;
    font-size: 24px;
    color: #6c6c6c;
  }
  .overview-card {
    border-radius: $border-radius;
    width: 16em;
    @include max-break-point(tablet) {
      width: auto;
    }
  }

  .detail-card,
  .chart-container {
    border-radius: $border-radius;
    p {
      font-weight: 400;
      font-size: 16px;
      margin-bottom: 0px !important;
    }
    .field-value {
      color: $dark-6;
    }
    .detail-section {
      border-bottom: 1px solid #d5dce0;
    }
  }
  .link {
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #1882cf;
    cursor: pointer;
  }
  .search-section {
    .search-input {
      font-weight: 400;
      font-size: 14px;
      color: #7e7d7d;
      border: 0.5px solid $dark-1;
      border-radius: 5px;
    }
  }
  .card-title {
    .title {
      font-weight: 500;
      font-size: 14px;
      color: #474747;
    }
    .numbers {
      font-weight: 700;
      font-size: 16px;
      color: #474747;
    }
  }
}
