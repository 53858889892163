.settings {
  .tabs {
    font-weight: 400;
    font-size: 14px;
    color: $dark-5;
    border-bottom: 1px solid $primary !important;
    width: fit-content;

    @include max-break-point(tablet) {
      display: inline-block;
      width: 100%;
    }
  }
  .add-btn {
    background: rgba($color: $primary, $alpha: 0.1);
    border: 1px solid $primary;
    border-radius: 8px;
  }
}
