.registration-page {
  min-height: 100vh;
  padding: 3em 1em;

  .company-branding {
    background: #bffbff;
    border-radius: 12px;

    @include max-break-point(tablet-landscape) {
      img {
        @include size(7em);
      }
    }
  }

  .registration-container {
    position: relative;
    background: $white;
    border-radius: 12px;
    box-shadow: 3px 3px 15px 3px rgba(0, 0, 0, 0.15);
    width: 80%;
    height: auto;

    @include max-break-point(tablet-landscape) {
      width: 90%;
      overflow: hidden;
    }

    .registration-button {
      background: rgba($color: $primary, $alpha: 0.2);
      border: 1px solid $primary;
      padding: 0.75em 0.54em;
      border-radius: 8px;
    }
    .icons {
      background: transparent;
      border-left: none;
      border-radius: 4px;
    }

    .password-field {
      border-right: none;
    }
  }
  .input-field {
    background: $white;
    border-radius: 4px;
  }

}
