.profile {
  .profile-section {
    border-radius: 12px;
    .alert-section {
      background: rgba(29, 178, 126, 0.2);
      height: 5em;
    }
    .edit-title {
      border-bottom: 1px solid #b5b5b5;
      p {
        font-weight: $font-weight-normal;
        font-size: 20px;
        @include max-break-point(tablet) {
          font-size: 14px;
        }
      }
    }
    .profile-avatar {
      top: -1.5em;
    }
    .image-section {
      border: 6px solid $primary;
      @include size(8em);
    }
    .change-profile-image {
      top: 4em;
      left: 0em;
      background: rgba(19, 18, 18, 0.8);
      font-weight: $font-weight-normal;
      font-size: $title-font-size;
      width: 7.5em;
    }
    .profile-picture {
      @include size(100%);
      object-fit: cover;
    }
    .user-details {
      .name {
        font-weight: $font-weight-normal;
        font-size: $h3-font-size;
      }
      .username,
      .email {
        font-weight: $font-weight-light;
        font-size: $p-font-size;
        color: $dark-6;
      }
    }
  }
  .edit-button {
    background: rgba(29, 178, 126, 0.2);
    border: 1px solid $primary;
    border-radius: 4px;
    color: $primary;
  }
  .editing {
    input {
      border: none;
      padding-left: 0px;
      font-weight: $font-weight-normal;
      font-size: $p-font-size;
      color: rgba(0, 0, 0, 0.5);
    }
    input[type="date"]::-webkit-inner-spin-button,
    input[type="date"]::-webkit-calendar-picker-indicator {
      display: none;
      -webkit-appearance: none;
    }

    .form-select:disabled {
      background: transparent !important;
      border: 0;
      font-weight: $font-weight-normal;
      font-size: $p-font-size;
      color: rgba(0, 0, 0, 0.5);
    }
  }
}
