// Importing Inter font library from google fonts
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
// Importing Open Sans font library from google fonts
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");

$white: #fff;
$black: #000;
$blue: #1a8fe3;
$purple: #6610f2;
$red: #d11149;
$orange: #f37933;
$yellow: #e6c229;

$primary: #1db27e;
$border-color: #c2c9d1;
$darkslategray-color: #344048;
$dark-gray-color: #212526;

$hr: #45504d;
$bg-secondary: #2f3837;
$text-color: #b8c1bf;
$primary-overlay: #222b29cc;
$table-border: #404a49;
$smoke: #eef4f3;
$vin-smoke: #fafefd;
$dim-border: #222b29;
$custom-yellow: #d4bd56;
$input-bg: #222a29;
$modal-bg: #374341;
$bg-warn: #d9bc3f;
$bg-dark: #171e1d;
$success: #1db27e;
$btn-light: #778583;
$maroon: #ff6f6f;
$light-maroon: #ff9897;
$error: #ff4646;
$info: #2196f3;
$line: #3e4342;
$soft-blue: #96cbf2;
$soft-white: #c4c4c4;
$table-header: #1db27e;
$table-header-primary: #f5f5dc;
$deep-green: #28a74540;
$dark-gray: #393c3b;
$warning-color2: #d79629;
$primary-green-color: #00c08a;
$input-field-color: #424a50;
$secondary-gray-text-color: #989797;
$shadow: 3px 3px 15px rgba(0, 0, 0, 0.15);

//custom theme colors
$custom-theme-colors: (
  "dark": #f2f2f2,
  "dark-1": #d8d8d8,
  "dark-2": #c5c5c5,
  "dark-3": #aaaaaa,
  "dark-4": #999999,
  "dark-5": #808080,
  "dark-6": #747474,
  "dark-7": #5b5b5b,
  "dark-8": #5b5b5b,
  "dark-9": #464646,
  "dark-10": #363636,
  "light": #fefefc,
  "light-1": #fcfcf4,
  "light-2": #fafaef,
  "light-4": #f8f8e8,
  "light-5": #f7f7e3,
  "light-6": #f5f5dc,
  "light-7": #dfdfc8,
  "light-8": #aeae9c,
  "light-9": #878779,
  "light-10": #67675c,
);

//brand colors
$theme-colors: (
  primary: #1a8fe3,
  secondary: #808080,
  third: #f5f5dc,
  black: #000000,
  blue: #1882cf,
  purple: #660ff1,
);

// brand color variables
$primary-color: map-get($theme-colors, "primary");
$third-color: map-get($theme-colors, "third");
$secondary-color: map-get($theme-colors, "secondary");
$black-color: map-get($theme-colors, "black");
$blue-color: map-get($theme-colors, "blue");
$purple-color: map-get($theme-colors, "purple");

//custom theme color variables
$dark: map-get($custom-theme-colors, "dark");
$dark-1: map-get($custom-theme-colors, "dark-1");
$dark-2: map-get($custom-theme-colors, "dark-2");
$dark-3: map-get($custom-theme-colors, "dark-3");
$dark-4: map-get($custom-theme-colors, "dark-4");
$dark-5: map-get($custom-theme-colors, "dark-5");
$dark-6: map-get($custom-theme-colors, "dark-6");
$dark-7: map-get($custom-theme-colors, "dark-7");
$dark-8: map-get($custom-theme-colors, "dark-8");
$dark-9: map-get($custom-theme-colors, "dark-9");
$dark-10: map-get($custom-theme-colors, "dark-10");

$light: map-get($custom-theme-colors, "light");
$light-1: map-get($custom-theme-colors, "light-1");
$light-2: map-get($custom-theme-colors, "light-2");
$light-3: map-get($custom-theme-colors, "light-3");
$light-4: map-get($custom-theme-colors, "light-4");
$light-5: map-get($custom-theme-colors, "light-5");
$light-6: map-get($custom-theme-colors, "light-6");
$light-7: map-get($custom-theme-colors, "light-7");
$light-8: map-get($custom-theme-colors, "light-8");
$light-9: map-get($custom-theme-colors, "light-9");
$light-10: map-get($custom-theme-colors, "light-10");

$linearGradient: linear-gradient(
  180deg,
  #2980b9 -42.25%,
  #6dd5fa 50.53%,
  rgba(255, 255, 255, 0.47) 155.15%
);
$primary-linear-gradient: linear-gradient(
  0deg,
  #020202 1.46%,
  rgba(2, 2, 2, 0) 111.56%
);

// font family
$default-font-family: "Inter", sans-serif;
$inter-font-family: "Inter", sans-serif;
$opensans-font-family: "Open Sans", sans-serif;

//font-weight
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 500;
$font-weight-mid-bold: 600;
$font-weight-bolder: 700;
$font-weight-high-bold: 800;

//font-sizes
$h1-font-size: 2rem;
$h2-font-size: 1.75rem;
$h3-font-size: 1.563rem;
$h4-font-size: 1.375rem;
$h5-font-size: 1.25rem;
$h6-font-size: 1.125rem;
$title-font-size: 1rem;
$p-font-size: 0.825rem;
$small-font-size: 0.75rem;

$line-height: 1.375em;

$border-radius: 0.25rem;
$border-radius-lg: 1.25rem;
