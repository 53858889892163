.page-nav-wrap {
  @extend .d-flex, .align-items-center, .justify-content-center;
  > ul {
    > li {
      @extend .d-inline-block;
      > a {
        @extend .d-flex,
          .align-items-center,
          .justify-content-center,
          .rounded-circle;
        @include size(3em);
        line-height: 60px;
        background: $white;
        color: $primary !important;
        font-weight: $font-weight-bolder;
        transition: all 0.3s ease-in-out;
        margin: 0 2px;

        i {
          margin-top: 2px;
        }

        &:hover {
          color: $white !important;
          background: $primary;
        }
      }

      &.active {
        a {
          color: $white !important;
          background: $primary;
        }
      }
      &.disabled {
        a {
          background-color: $light-3;
          cursor: initial;

          &:hover {
            color: $primary !important;
          }
        }
      }
    }
  }
  @include max-break-point(tablet) {
    ul {
      li {
        a {
          @include size(3.5em);
          margin-top: 10px;
          line-height: 50px;
        }
      }
    }
  }
}
.page-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 1rem 0px;
  @media (max-width: 767px) {
    display: block;
  }
  .showing {
    @media (max-width: 767px) {
      margin: 10px;
    }
  }

  .searchbar {
    @extend .position-relative;
    background: $white;

    svg {
      position: absolute;
      top: 0px;
      left: 13px;
      transform: translateY(80%);
      fill: $light-2;
    }
    input {
      outline: 0;
    }
  }

  .select-per-page {
    height: fit-content;
    border: 1px solid $light-8;
    &:active {
      outline: none;
    }
    &:focus {
      outline: none;
    }
  }
}
.transaction-pagination {
  > .pagination {
    @media (max-width: 767px) {
      margin-top: 1rem;
    }
    > li {
      > a {
        font-size: 14px;
        margin: 0 5px;
        background: $white;
        color: $primary;
        padding: 5px 10px;
        border-radius: 6px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

        &:hover {
          color: $white;
          background: $primary;
        }
      }

      &.active {
        a {
          color: $white;
          background: $primary;
        }
      }
      &.disabled {
        a {
          background-color: $border-color;
          cursor: initial;

          &:hover {
            color: $primary;
          }
        }
      }
    }
  }
}
