.navbar-wrap {
  .navbar {
    transition: all 0.2s ease-in-out;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    &.navbar-scrolled {
      background: $light;
    }
  }

  .nav-link {
    margin: 5px;

    &:hover {
      text-decoration: none;
      color: $black;
    }
  }
  .nav-logo {
    @include size(88px, 111px);

    @include max-break-point(tablet) {
      width: 80px;
      height: auto;
    }
  }
}
.header-gutter {
  height: 120px;

  @include max-break-point(tablet) {
    height: 80px;
  }
}

.signup-btn {
  background: rgba($color: $primary, $alpha: 0.2);
  border: 1px solid rgba($color: $primary, $alpha: 0.6);
  border-radius: 4px;
  padding: 0.67em 1em;
  color: $primary;
  font-weight: 600;
  margin: 5px;
  &:hover {
    color: $primary;
  }
}

.login-btn {
  background-color: $primary;
  border: 1px solid $primary;
  border-radius: 4px;
  color: #fff;
  padding: 0.67em 1em;
  font-weight: 600;
  margin: 5px;
  &:hover {
    color: $white;
  }
}
