.dashboard {
  height: 100vh;
}

.navbar-toggler {
  border: 0;
  cursor: pointer;

  &:focus {
    outline: none;
    box-shadow: none;
  }
}
.hamburger-menu {
  @extend .d-flex, .align-items-center;
  height: 1.5em;
}

.main-content {
  overflow-y: auto;
  height: calc(100vh - 96px);
  width: calc(100% - 15em);

  &.show {
    width: calc(100% - 84px);

    @include max-break-point(tablet) {
      width: 100%;
    }
  }
}
