.landing-page-wrapper {
  background: url("../../images/background.svg");
  background-size: cover;
  background-repeat: no-repeat;
}

.landing-container {
  .banner-section {
    border-radius: 8px;
    background-color: #bffbff;
    padding: 3rem;

    img {
      @media (max-width: 992px) {
        width: 150px;
      }
    }
    .banner-txt {
      padding-left: 3rem;
      h1 {
        font-weight: 700;
        font-size: 2.5rem;
        @media (max-width: 992px) {
          font-size: 22px;
        }
      }
      h3 {
        font-weight: 500;
        font-size: 2rem;
        padding-top: 1rem;
        @media (max-width: 992px) {
          font-size: 22px;
        }
        @media (max-width: 767px) {
          font-size: 20px;
        }
      }

      @media (max-width: 1200px) {
        padding-top: 4.5rem;
      }
      @media (max-width: 992px) {
        padding-top: 1.5rem;
        padding-left: 20px;
      }
    }
  }
}
// product card
.product-header {
  margin-bottom: 1.5rem;
  h5 {
    padding-left: 1rem;
  }
  h4 {
    font-weight: 600;
  }
}
.product-section {
  margin-bottom: 1.5rem;
  .product-card {
    background-color: $white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border-radius: 8px;
    .product-image {
      box-shadow: 1px 4px 5px 0px rgba(240, 240, 240, 0.4);
      width: 100%;
      aspect-ratio: 4/4;
      // height: 200px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .product-detail {
      padding: 1rem;
      font-size: 14px;
      .title {
        font-weight: 600;
        font-size: 18px;
        color: #212529;
      }
      p {
        color: $dark-6;
      }
    }
  }
}
