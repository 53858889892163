.learning-lists {
  .subtitle {
    font-weight: $font-weight-mid-bold;
    font-size: $h3-font-size;
    padding-bottom: 1rem;
  }
  .search-input {
    border-left: none;
  }

  .show-items {
    background: transparent;
    border: 1px solid #dddddd;
    border-radius: 8px;
    p {
      font-weight: $font-weight-mid-bold;
      font-size: $title-font-size;
      color: #333333;
    }
  }
  .page-options {
    li {
      font-weight: $font-weight-mid-bold;
      font-size: $title-font-size;
      color: #333333;
    }
    li:hover {
      background-color: $primary;
    }
  }
  .filter-button {
    background: rgba(72, 165, 233, 0.2);

    border: 1px solid #48a5e9;

    box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
   
  }
  .modules-image {
    border-bottom: 1px solid #c5c5c5;
    .learning-image{
      height: 100%;
      width: 100%;
    }
  }
  .progress {
    height: 0.8em;
  }
  .module-information {
    margin-top: 1rem;
    margin-left: 1rem;
    @media(max-width:767px){
      margin-top:2.5rem;
    }
    .module-name {
      font-weight: $font-weight-bold;
      font-size: $p-font-size;
      // @media (max-width:) {
        
      // }
    }
    .module-model {
      font-weight: $font-weight-normal;
      font-size: $p-font-size;
      color: $dark-6;
    }
  }
  .information,
  .progress-title {
    font-weight: $font-weight-normal;
    font-size: $p-font-size;
    color: $dark-6;
  }
  // .modules-image{
  //   max-height: 11em;
  //   overflow: hidden;
  // }
  .leaning-div{
    border-radius: 8px;
    height: auto;
  }
  .learning-item{
    margin-top: 6px;
  }
 
}
