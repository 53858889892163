.change-password {
    .input-field {
      background: $white;
      border-radius: 4px;
    }
  
    .change-password-form {
      background: $white;
      border-radius: 12px;
  
      @include max-break-point(tablet-landscape) {
        width: 90%;
        overflow: hidden;
        height: auto;
      }
  
      .confirm-button {
        background: rgba($color: $primary, $alpha: 0.2);
        border: 1px solid $primary;
        padding: 0.75em 0.54em;
        border-radius: 8px;
      }
      .icons {
        background: transparent;
        border-left: none;
        border-radius: 4px;
      }
  
      .password-field {
        border-right: none;
      }
    }
  }
  