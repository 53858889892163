.simple-table {
  border-radius: $border-radius;

  table {
    border-radius: $border-radius;

    .table-heading {
      background-color: #e8f4fc;
      font-weight: 500;
      font-size: 14px;
      align-items: center;
    }
    th {
      padding: 15px;
    }
    td {
      font-weight: 500;
      font-size: 16px;
      padding: 15px;
      text-align: left;
      color: $dark-6;
      border-bottom: 2px solid $light-8;
    }
  }
}
