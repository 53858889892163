:root {
  --ck-color-base-foreground: $white !important;
  --ck-color-base-background: $white !important;
  --ck-color-base-border: rgba($color: #1a92e2, $alpha: 0.1) !important;
  --ck-color-base-action: #53a336 !important;
  --ck-color-base-focus: #6cb5f9 !important;
  --ck-color-base-text: $white !important;
  --ck-color-base-active: #2977ff !important;
  --ck-color-base-active-focus: #0d65ff !important;
  --ck-color-base-error: #db3700 !important;
  --ck-color-focus-border-coordinates: 218, 81.8%, 56.9% !important;
  --ck-color-focus-border: hsl(
    var(--ck-color-focus-border-coordinates)
  ) !important;
  --ck-color-focus-outer-shadow: #cae1fc !important;
  --ck-color-focus-disabled-shadow: rgba(119, 186, 248, 0.3) !important;
  --ck-color-focus-error-shadow: rgba(255, 64, 31, 0.3) !important;
  --ck-color-text: var(--ck-color-base-text) !important;
  --ck-color-shadow-drop: rgba(0, 0, 0, 0.15) !important;
  --ck-color-shadow-drop-active: rgba(0, 0, 0, 0.2) !important;
  --ck-color-shadow-inner: rgba(0, 0, 0, 0.1) !important;
  --ck-color-button-default-background: transparent !important;
  --ck-color-button-default-hover-background: #1a92e2 !important;
  --ck-color-button-default-active-background: #1a92e2 !important;
  --ck-color-button-default-disabled-background: transparent !important;
  --ck-color-button-on-background: #1a92e2 !important;
  --ck-color-button-on-hover-background: #1a92e2 !important;
  --ck-color-button-on-active-background: #1a92e2 !important;
  --ck-color-button-on-disabled-background: #f0f2f4 !important;
  --ck-color-button-on-color: #2977ff !important;
  --ck-color-button-action-background: var(--ck-color-base-action) !important;
  --ck-color-button-action-hover-background: #4d9d30 !important;
  --ck-color-button-action-active-background: #4d9d30 !important;
  --ck-color-button-action-disabled-background: #7ec365 !important;
  --ck-color-button-action-text: var(--ck-color-base-background) !important;
  --ck-color-button-save: #008a00 !important;
  --ck-color-button-cancel: #db3700 !important;
  --ck-color-switch-button-off-background: #939393 !important;
  --ck-color-switch-button-off-hover-background: #7d7d7d !important;
  --ck-color-switch-button-on-background: var(
    --ck-color-button-action-background
  ) !important;
  --ck-color-switch-button-on-hover-background: #4d9d30 !important;
  --ck-color-switch-button-inner-background: var(
    --ck-color-base-background
  ) !important;
  --ck-color-switch-button-inner-shadow: rgba(0, 0, 0, 0.1) !important;
  --ck-color-dropdown-panel-background: var(
    --ck-color-base-background
  ) !important;
  --ck-color-dropdown-panel-border: var(--ck-color-base-border) !important;
  --ck-color-input-background: var(--ck-color-base-background) !important;
  --ck-color-input-border: var(--ck-color-base-border) !important;
  --ck-color-input-error-border: var(--ck-color-base-error) !important;
  --ck-color-input-text: var(--ck-color-base-text) !important;
  --ck-color-input-disabled-background: #f2f2f2 !important;
  --ck-color-input-disabled-border: var(--ck-color-base-border) !important;
  --ck-color-input-disabled-text: #757575 !important;
  --ck-color-list-background: var(--ck-color-base-background) !important;
  --ck-color-list-button-hover-background: var(
    --ck-color-button-default-hover-background
  ) !important;
  --ck-color-list-button-on-background: var(
    --ck-color-button-on-color
  ) !important;
  --ck-color-list-button-on-background-focus: var(
    --ck-color-button-on-color
  ) !important;
  --ck-color-list-button-on-text: var(--ck-color-base-background) !important;
  --ck-color-panel-background: var(--ck-color-base-background) !important;
  --ck-color-panel-border: var(--ck-color-base-border) !important;
  --ck-color-toolbar-background: var(--ck-color-base-background) !important;
  --ck-color-toolbar-border: var(--ck-color-base-border) !important;
  --ck-color-tooltip-background: var(--ck-color-base-text) !important;
  --ck-color-tooltip-text: var(--ck-color-base-background) !important;
  --ck-color-engine-placeholder-text: #707070 !important;
  --ck-color-upload-bar-background: #6cb5f9 !important;
  --ck-color-link-default: #0000f0 !important;
  --ck-color-link-selected-background: rgba(31, 176, 255, 0.1) !important;
  --ck-color-link-fake-selection: rgba(31, 176, 255, 0.3) !important;
  --ck-color-highlight-background: #ff0 !important;
  --ck-disabled-opacity: 0.5 !important;
  --ck-focus-outer-shadow-geometry: 0 0 0 3px !important;
  --ck-focus-outer-shadow: var(--ck-focus-outer-shadow-geometry)
    var(--ck-color-focus-outer-shadow) !important;
  --ck-focus-disabled-outer-shadow: var(--ck-focus-outer-shadow-geometry)
    var(--ck-color-focus-disabled-shadow) !important;
  --ck-focus-error-outer-shadow: var(--ck-focus-outer-shadow-geometry)
    var(--ck-color-focus-error-shadow) !important;
  --ck-focus-ring: 1px solid var(--ck-color-focus-border) !important;
  --ck-font-size-base: 13px !important;
  --ck-line-height-base: 1.84615 !important;
  --ck-font-face: Helvetica, Arial, Tahoma, Verdana, Sans-Serif !important;
  --ck-font-size-tiny: 0.7em !important;
  --ck-font-size-small: 0.75em !important;
  --ck-font-size-normal: 1em !important;
  --ck-font-size-big: 1.4em !important;
  --ck-font-size-large: 1.8em !important;
  --ck-ui-component-min-height: 2.3em !important;
}

.ck-editor__main {
  border: 1px solid rgba($color: #1a92e2, $alpha: 0.1) !important;
  background: $white !important;
  color: $black;
  height: 100% !important;
  min-height: 150px !important;
  max-height: 500px !important;
  overflow-y: auto;

  .ck-content {
    padding: 10px !important ;
    border: 1px solid rgba($color: #1a92e2, $alpha: 0.1) !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color: $black !important ;
  }
}
.ck.ck-editor {
  border: 2px solid $primary !important;
  border-radius: 8px;
}

.ck.ck-toolbar {
  background: rgba($color: $white, $alpha: 0.1) !important;
  color: $white !important;
}

.ck.ck-editor__main > .ck-editor__editable {
  border: 0 !important;
}

.ck.ck-dropdown__panel,
.ck.ck-list {
  background: $white !important;
  color: $black !important;
}

.ck.ck-button.ck-on,
a.ck.ck-button.ck-on {
  color: $white !important;
}

.ck-reset_all :not(.ck-reset_all-excluded *),
.ck.ck-reset_all {
  border: 0 !important;
}

.ck-reset_all :not(.ck-reset_all-excluded *),
.ck.ck-reset,
.ck.ck-reset_all {
  background: $primary !important;
}

.ck-reset_all,
.ck.ck-reset,
.ck.ck-reset_all {
  background: $primary !important;
}

.ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
  border: 0 !important;
}

.ck.ck-sticky-panel__content.ck-sticky-panel__content_sticky {
  background: $primary !important;
}

.ck.ck-button:active,
.ck.ck-button:focus,
a.ck.ck-button:active,
a.ck.ck-button:focus {
  border: 0 !important;
  outline: 0 !important;
  box-shadow: none !important;
}

.ck.ck-button:not(.ck-disabled):hover,
a.ck.ck-button:not(.ck-disabled):hover {
  background: #1a92e2 !important;
}
