.sidebar {
  height: calc(100vh - 96px);
  width: inherit;
  transition: width 0.3s ease-in;
  overflow: hidden;
  border-right: 1px solid $border-color;

  &.show {
    min-width: 15em;
  }

  &.hide {
    width: 84px;
  }

  @include max-break-point(tablet) {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1070;

    &:after {
      content: "";
      width: 100%;
      height: 100%;
      background: rgba($color: $black, $alpha: 0.6);
      position: absolute;
      left: 0;
      top: 0;
    }

    &.show {
      display: block;

      .nav {
        width: 15em;
        background: $white;
        position: relative;
        z-index: 1002;
        height: 100%;
      }
    }
    &.hide {
      display: none;
    }
    li {
      &.mobile-nav {
        span {
          display: block;
        }
      }
    }
  }

  .sidebar-icon {
    height: 1.3em;
    width: 1.3em;
  }
  li {
    cursor: pointer;
    font-size: $p-font-size;

    &.list-group-item {
      word-break: keep-all;
      white-space: nowrap;

      &.mobile-nav {
        span {
          display: none;
        }
      }
    }
  }

  .active-navigation {
    background-color: #d3f1e5;
    border-radius: 0.5em;
    color: #1db27e;
  }
  .inactive-navigation {
    color: #555454;
  }
}
