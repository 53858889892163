.user-section {
  .toggle-dropdown {
    background: transparent;
  }
  .profile-options {
    background: $white;
    border: 1px solid #d3d3d3;
    border-radius: 4px;

    li:hover {
      background: #e8f4fc;
    }
    p {
      font-weight: $font-weight-normal;
      font-size: $title-font-size;
      color: $dark-10;
      margin-bottom: 0px !important;
    }
  }
  .dropdown-icon {
    @include size(1.5em);
  }
}
